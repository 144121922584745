import numberToWords from "number-to-words";

const appUtils = {
  formaterCurrencyToNumber(value) {
    return Number(value.replace(/[^0-9.-]+/g, ""));
  },
  isNumeric(str) {
    if (typeof str != "string") return false;
    return !isNaN(str) && !isNaN(parseFloat(str));
  },
  formatterInputCurrency(value) {
    if (this.isNumeric(value)) {
      return this.formaterCurrency(value);
    } else {
      return;
    }
  },
  numberFormat(number, currencyUnit) {
    let data = number
      ?.toString()
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // let data = number.toLocaleString(undefined, { minimumFractionDigits: 0 });
    if (currencyUnit) {
      data += ` ${currencyUnit}`;
    }
    return data;
  },
  debounce(func, wait, immediate) {
    let timeout;

    return function () {
      let context = this;
      let args = arguments;
      let callNow = immediate && !timeout;

      clearTimeout(timeout);

      timeout = setTimeout(function () {
        timeout = null;

        if (!immediate) {
          func.apply(context, args);
        }
      }, wait);
      if (callNow) func.apply(context, args);
    };
  },
  numberToWords(number) {
    const dvBlock = "1 nghìn triệu tỷ".split(" ");

    var str = parseInt(number) + "";
    var i = 0;
    var arr = [];
    var index = str.length;
    var result = [];
    var rsString = "";

    if (index == 0 || str == "NaN") {
      return "";
    }

    while (index >= 0) {
      arr.push(str.substring(index, Math.max(index - 3, 0)));
      index -= 3;
    }
    for (i = arr.length - 1; i >= 0; i--) {
      if (arr[i] != "" && arr[i] != "000") {
        result.push(convert_block_three(arr[i]));
        if (dvBlock[i]) {
          result.push(dvBlock[i]);
        }
      }
    }
    rsString = result.join(" ");

    return (
      rsString.replace(/[0-9]/g, "").replace(/ /g, " ").replace(/ $/, "") +
      " đồng"
    );
  },

  toNonAccentVietnamese(str) {
    str = str.replace(/A|Á|À|Ã|Ạ|Â|Ấ|Ầ|Ẫ|Ậ|Ă|Ắ|Ằ|Ẵ|Ặ/g, "A");
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/E|É|È|Ẽ|Ẹ|Ê|Ế|Ề|Ễ|Ệ/, "E");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/I|Í|Ì|Ĩ|Ị/g, "I");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/O|Ó|Ò|Õ|Ọ|Ô|Ố|Ồ|Ỗ|Ộ|Ơ|Ớ|Ờ|Ỡ|Ợ/g, "O");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/U|Ú|Ù|Ũ|Ụ|Ư|Ứ|Ừ|Ữ|Ự/g, "U");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/Y|Ý|Ỳ|Ỹ|Ỵ/g, "Y");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/Đ/g, "D");
    str = str.replace(/đ/g, "d");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
    return str;
  },
  toSnakeCase(inputString) {
    return inputString
      .replace(/[^\w\s]/g, "") // Remove special characters
      .trim() // Trim leading and trailing spaces
      .replace(/\s+/g, "_") // Replace spaces with underscores
      .toLowerCase(); // Convert to lowercase
  },
  richToString(rich) {
    return rich.richText.map(({ text }) => text?.trim()).join("");
  },
};

const defaultNumbers = " hai ba bốn năm sáu bảy tám chín";

const chuHangDonVi = ("1 một" + defaultNumbers).split(" ");
const chuHangChuc = ("lẻ mười" + defaultNumbers).split(" ");
const chuHangTram = ("không một" + defaultNumbers).split(" ");

const convert_block_three = (number) => {
  if (number == "000") return "";
  var _a = number + "";

  switch (_a.length) {
    case 0:
      return "";
    case 1:
      return chuHangDonVi[_a];
    case 2:
      return convert_block_two(_a);
    case 3:
      var chuc_dv = "";
      if (_a.slice(1, 3) != "00") {
        chuc_dv = convert_block_two(_a.slice(1, 3));
      }
      var tram = chuHangTram[_a[0]] + " trăm";
      return tram + " " + chuc_dv;
  }
};

const convert_block_two = (number) => {
  var dv = chuHangDonVi[number[1]];
  var chuc = chuHangChuc[number[0]];
  var append = "";
  if (number[0] > 0 && number[1] == 5) {
    dv = "lăm";
  }
  if (number[0] > 1) {
    append = " mươi";
    if (number[1] == 1) {
      dv = " mốt";
    }
  }

  return chuc + "" + append + " " + dv;
};

export default appUtils;
